// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-tsx": () => import("./../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-causas-tsx": () => import("./../../src/pages/causas.tsx" /* webpackChunkName: "component---src-pages-causas-tsx" */),
  "component---src-pages-egresos-tsx": () => import("./../../src/pages/egresos.tsx" /* webpackChunkName: "component---src-pages-egresos-tsx" */),
  "component---src-pages-glosario-tsx": () => import("./../../src/pages/glosario.tsx" /* webpackChunkName: "component---src-pages-glosario-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mortalidad-tsx": () => import("./../../src/pages/mortalidad.tsx" /* webpackChunkName: "component---src-pages-mortalidad-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

